<template>
	<div class="notificationListContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }">Notification History</a-breadcrumb-item>
			</a-breadcrumb>
		</div>

		<a-table
			:columns="column"
			size="small"
			:scroll="{ x: width + width, y: height - 200 }"
			style="width: 100%"
			bordered
			:data-source="list"
			:loading="loading"
			class="tableBody"
			@change="handleTableChange"
			:pagination="{
        				current: pagination.current,
        				pageSize: pagination.pageSize,
        				total: pagination.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
			><template #bodyCell="{ text, column, record }">
				<!-- <template v-if="(column.dataIndex === 'notificationTitle' || column.dataIndex == 'notificationDescription' || column.dataIndex === 'notificationBody') && text">
					{{ truncateText(text, 35) }}
				</template> -->

				<template v-if="column.dataIndex === 'notificationTitle'">
					<div @click="openNotificationTitleModal(record)">
						<span>{{ truncateText(record.notificationTitle, 35) }}</span>
					</div>
				</template>

				<template v-if="column.dataIndex === 'notificationDescription'">
					<div @click="openNotificationDescriptionModal(record)">
						<span>{{ truncateText(record.notificationDescription, 35) }}</span>
					</div>
					<div v-if="!record.notificationDescription || record.notificationDescription.trim() === ''">-</div>
				</template>

				<template v-if="column.dataIndex === 'notificationBody'">
					<div @click="openNotificationBodyModal(record)">
						<span>{{ truncateText(record.notificationBody, 35) }}</span>
					</div>
				</template>
				<!-- <template v-else-if="column.dataIndex === 'fromAgeValue'">
					<span v-if="!record.fromAgeValue.trim().length">-</span>
					<span v-else>{{ record.fromAgeValue }}</span>
				</template>
				<template v-else-if="column.dataIndex === 'toAgeValue'">
					<span v-if="!record.toAgeValue.trim().length">-</span>
					<span v-else>{{ record.toAgeValue }}</span>
				</template> -->

				<template v-if="column.dataIndex === 'fromAgeValue'">
					<div>
						<span>{{ trimText(record.fromAgeValue) }}</span>
					</div>
					<span v-if="!record.fromAgeValue || !trimText(record.fromAgeValue).length">-</span>
				</template>
				<template v-if="column.dataIndex === 'toAgeValue'">
					<div>
						<span>{{ trimText(record.toAgeValue) }}</span>
					</div>
					<span v-if="!record.toAgeValue || !trimText(record.toAgeValue).length">-</span>
				</template>

				<template v-else-if="column.dataIndex === 'cities'">
					<span>
						<div v-for="(city, index) in record.cities" @click="openCityModal(record)" class="tagContainer">
							<div class="tags">
								<a-tag :key="city" class="tag" v-if="index <= 1">
									{{ city }}
								</a-tag>
							</div>
						</div>
						<span v-if="!record.cities.length">-</span>
						<a-tag v-else-if="record.cities.length >= 3" :style="{ padding: '5px 8px', fontSize: '13px' }" @click="openCityModal(record)">More...</a-tag>
					</span>
				</template>
				<template v-else-if="column.dataIndex === 'states'">
					<div v-for="(state, index) in record.states" @click="openStateModal(record)" class="tagContainer">
						<a-tag :key="state" class="tag" v-if="index <= 1">
							{{ state }}
						</a-tag>
					</div>

					<span v-if="!record.states.length">-</span>
					<a-tag v-else-if="record.states.length >= 3" :style="{ padding: '5px 8px', fontSize: '13px' }" @click="openStateModal(record)">More...</a-tag>
				</template>
				<template v-else-if="column.dataIndex === 'countries'">
					<div v-for="(country, index) in record.countries" @click="openCountryModal(record)" class="tagContainer">
						<a-tag :key="country" class="tag" v-if="index <= 1">
							{{ country }}
						</a-tag>
					</div>
					<span v-if="!record.countries.length">-</span>
					<a-tag v-else-if="record.countries.length >= 3" :style="{ padding: '5px 8px', fontSize: '13px' }" @click="openCountryModal(record)">More...</a-tag>
				</template>

				<template v-else-if="column.dataIndex === 'allUser'">
					<a-tag :color="record.allUser == true ? 'green' : 'volcano'" class="tag">{{ record.allUser ? true : false }}</a-tag>
				</template>

				<template v-else-if="column.dataIndex === 'dormantUser'">
					<a-tag :color="record.dormantUser == true ? 'green' : 'volcano'" class="tag">{{ record.dormantUser ? true : false }}</a-tag>
				</template>
				<template v-else-if="column.dataIndex === 'activeUser'">
					<a-tag :color="record.activeUser == true ? 'green' : 'volcano'" class="tag">{{ record.activeUser ? true : false }}</a-tag>
				</template>
				<template v-else-if="column.dataIndex === 'inCompleteUser'">
					<a-tag :color="record.inCompleteUser == true ? 'green' : 'volcano'" class="tag">{{ record.inCompleteUser ? true : false }}</a-tag>
				</template>
				<template v-else-if="column.dataIndex === 'gender'">
					<span>
						<a-tag v-for="gender in record.gender" :key="gender" :color="gender === 'm' ? 'volcano' : 'green'" class="tag">
							<span v-if="gender === 'm'">Male</span>
							<span v-else-if="gender === 'f'">Female</span>
							<span v-else>{{ gender }}</span>
						</a-tag>
						<span v-if="!record.gender.length">-</span>
					</span>
				</template>

				<template v-else-if="column.dataIndex == 'createdAt'">
					<span>{{ record.createdAt && dayjs.utc(record.createdAt).format("DD-MMM-YYYY  HH:mm") }}</span>
				</template>
			</template>
		</a-table>
		<a-modal v-model:visible="isCityModalOpen" title="City ">
			<template #footer>
				<a-button key="submit" type="primary" @click="isCityModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedCityList.length > 0">
				<a-tag v-for="city in selectedCityList" class="tag">
					{{ city }}
				</a-tag>
			</div>
			<div v-else>No cities found</div>
		</a-modal>

		<a-modal v-model:visible="isStateModalOpen" title="State ">
			<template #footer>
				<a-button key="submit" type="primary" @click="isStateModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedStateList.length > 0">
				<a-tag v-for="state in selectedStateList" class="tag">
					{{ state }}
				</a-tag>
			</div>
			<div v-else>No State found</div>
		</a-modal>
		<a-modal v-model:visible="isCountryModalOpen" title="Country ">
			<template #footer>
				<a-button key="submit" type="primary" @click="isCountryModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedCountryList.length > 0">
				<a-tag v-for="country in selectedCountryList" class="tag">
					{{ country }}
				</a-tag>
			</div>
			<div v-else>No Country found</div>
		</a-modal>

		<a-modal v-model:visible="isNotificationTitleModalOpen" title="Notification Title">
			<template #footer>
				<a-button key="submit" type="primary" @click="isNotificationTitleModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedNotificationTitleList.length > 0">
				<span v-for="notificationTitle in selectedNotificationTitleList" class="modelTex">
					{{ notificationTitle }}
				</span>
			</div>
			<div v-else>No notification title found</div>
		</a-modal>

		<a-modal v-model:visible="isNotificationDescriptionModalOpen" title="Notification Description">
			<template #footer>
				<a-button key="submit" type="primary" @click="isNotificationDescriptionModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedNotificationDescriptionList.length > 0">
				<span v-for="notificationDescription in selectedNotificationDescriptionList" class="modelTex">
					{{ notificationDescription }}
				</span>
			</div>
			<div v-else>No Description found</div>
		</a-modal>

		<a-modal v-model:visible="isNotificationBodyModalOpen" title="Notification Body">
			<template #footer>
				<a-button key="submit" type="primary" @click="isNotificationBodyModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedNotificationBodyList.length > 0" class="modelTex">
				<span v-for="notificationBody in selectedNotificationBodyList" class="modelTex">
					{{ notificationBody }}
				</span>
			</div>
			<div v-else>No Description found</div>
		</a-modal>
	</div>
</template>

<script lang="ts" setup>
	import { getNotificationListApi } from "@/services/api/user";
	import { ref } from "vue";
	import { truncateText, useScreenSize } from "../utils/index";
	import dayjs from "dayjs";

	const loading = ref(false);
	const list = ref([]);
	const pagination = ref({
		pageSize: 20,
		total: 0,
		current: 1,
		totalPages: 0,
	});
	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const userListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sort: "modified",
		searchBy: null,
		search: null,
	});
	const filteredInfo = ref();

	const isCityModalOpen = ref(false);
	const isStateModalOpen = ref(false);
	const isCountryModalOpen = ref(false);

	const selectedCityList = ref<string[]>([]);
	const selectedStateList = ref<string[]>([]);
	const selectedCountryList = ref<string[]>([]);
	const isNotificationTitleModalOpen = ref(false);
	const selectedNotificationTitleList = ref<string[]>([]);
	const isNotificationDescriptionModalOpen = ref(false);
	const selectedNotificationDescriptionList = ref<string[]>([]);
	const isNotificationBodyModalOpen = ref(false);
	const selectedNotificationBodyList = ref<string[]>([]);
	const column = [
		{
			title: "Heading",
			dataIndex: "notificationTitle",
			key: "notificationTitle",
			width: 270,
		},
		{
			title: "Body ",
			dataIndex: "notificationBody",
			key: "notificationBody",
			width: 270,
		},

		{
			title: "Date Sent",
			dataIndex: "createdAt",
			key: "createdAt",
			width: 160,
			align: "center",
		},

		{
			title: "User received",
			dataIndex: "successfullyReachCount",
			key: "successfullyReachCount",
			width: 160,
			align: "center",
		},

		{
			title: "Total User",
			dataIndex: "totalUserCount",
			key: "totalUserCount",
			width: 160,
			align: "center",
		},
		{
			title: "Key",
			dataIndex: "notificationKey",
			key: "notificationKey",
			width: 100,
			align: "center",
		},

		{
			title: "Gender",
			dataIndex: "gender",
			key: "gender",
			width: 150,
			align: "center",
		},
		{
			title: "Start Age Value",
			dataIndex: "fromAgeValue",
			key: "fromAgeValue",
			width: 150,
			align: "center",
		},
		{
			title: "To Age Value",
			dataIndex: "toAgeValue",
			key: "toAgeValue",
			width: 150,
			align: "center",
		},
		{
			title: "All User",
			dataIndex: "allUser",
			key: "allUser",
			width: 140,
			align: "center",
		},
		{
			title: "Dormant User",
			dataIndex: "dormantUser",
			key: "dormantUser",
			width: 140,
			align: "center",
		},
		{
			title: "Active User",
			dataIndex: "activeUser",
			key: "activeUser",
			width: 140,
			align: "center",
		},
		{
			title: "Incomplete User",
			dataIndex: "inCompleteUser",
			key: "inCompleteUser",
			width: 140,
			align: "center",
		},
		{
			title: "City",
			dataIndex: "cities",
			key: "cities",
			width: 300,
			align: "center",
		},
		{
			title: "State",
			dataIndex: "states",
			key: "states",
			width: 300,
			align: "center",
		},
		{
			title: "Country",
			dataIndex: "countries",
			key: "countries",
			width: 300,
			align: "center",
		},

		{
			title: "Description ",
			dataIndex: "notificationDescription",
			key: "notificationDescription",
			width: 300,
			align: "center",
		},
	];
	const { height, width } = useScreenSize();
	const fetchNotificationListApi = async () => {
		try {
			loading.value = true;
			const apiRes = await getNotificationListApi({ pageNo: userListPagination.value.pageNo, pageLimit: userListPagination.value.pageLimit }, { ...userListFilter.value });
			list.value = apiRes?.item || [];
			pagination.value.total = apiRes?.pagination.total;
			pagination.value.current = apiRes?.pagination.currentPage;
			pagination.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};
	fetchNotificationListApi();

	const handleTableChange = (pagination: any, filters: any) => {
		filteredInfo.value = filters;
		userListPagination.value.pageNo = Number(pagination.current);
		userListFilter.value.pageNo = Number(pagination.current);
		userListPagination.value.pageLimit = Number(pagination.pageSize);
		fetchNotificationListApi();
	};

	const openCityModal = (record: any) => {
		selectedCityList.value = record.cities;
		isCityModalOpen.value = true;
	};

	const openStateModal = (record: any) => {
		selectedStateList.value = record.states;
		isStateModalOpen.value = true;
	};

	const openCountryModal = (record: any) => {
		selectedCountryList.value = record.countries;
		isCountryModalOpen.value = true;
	};

	const openNotificationTitleModal = (record: any) => {
		selectedNotificationTitleList.value = [record.notificationTitle];
		isNotificationTitleModalOpen.value = true;
	};

	const openNotificationDescriptionModal = (record: any) => {
		selectedNotificationDescriptionList.value = [record.notificationDescription];
		isNotificationDescriptionModalOpen.value = true;
	};

	const openNotificationBodyModal = (record: any) => {
		selectedNotificationBodyList.value = [record.notificationBody];
		isNotificationBodyModalOpen.value = true;
	};
	const trimText = (value: any) => {
		return String(value).trim();
	};
</script>

<style scoped>
	.notificationListContainer {
		padding: 22px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.tagContainer {
		display: inline-flex;
		flex-wrap: nowrap;
		gap: 4px;
	}
	.tag {
		padding: 5px 8px;
		font-size: 13px;
		margin: 6px 4px;
	}
	.modelTex {
		font-size: 16px;
	}
</style>
