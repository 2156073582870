<template>
	<div class="EmailListContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }">Email History</a-breadcrumb-item>
			</a-breadcrumb>
		</div>

		<a-table
			:columns="column"
			:scroll="undefined"
			style="width: 100%"
			bordered
			:data-source="list"
			:loading="loading"
			class="tableBody"
			@change="handleTableChange"
			:pagination="{
        				current: contentsPagination.current,
        				pageSize: contentsPagination.pageSize,
        				total: contentsPagination.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
		>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column.dataIndex === 'emailSubject'">
					<div @click="openSubjectModal(record)">
						<span>{{ truncateText(record.emailSubject, 35) }}</span>
					</div>
				</template>

				<template v-if="column.dataIndex === 'emailBody'">
					<div @click="openBodyModal(record)">
						<span>{{ truncateText(record.emailBody, 35) }}</span>
					</div>
				</template>

				<template v-else-if="column.dataIndex == 'createdAt'">
					<span>{{ record.createdAt && dayjs.utc(record.createdAt).format("DD-MMM-YYYY  HH:mm") }}</span>
				</template>
			</template>

			<template #action="{ record }">
				<a-button type="primary" @click="openEmailDrawer(record)" class="buttons"><FolderViewOutlined /> View</a-button>
			</template>
		</a-table>

		<a-modal v-model:visible="isSubjectModalOpen" title="Email Subject" :width="600">
			<template #footer>
				<a-button key="submit" type="primary" @click="isSubjectModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectSubject.length > 0">
				<span v-for="subject in selectSubject" class="modelTex">
					{{ subject }}
				</span>
			</div>
			<div v-else>No Subject found</div>
		</a-modal>

		<a-modal v-model:visible="isBodyModalOpen" title="Email Body" :width="900">
			<template #footer>
				<a-button key="submit" type="primary" @click="isBodyModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectBody.length > 0" v-html="selectBody"></div>
			<div v-else>No Body found</div>
		</a-modal>
	</div>
	<a-drawer v-model:visible="isListDrawerOpen" title="Recipients List" placement="right" width="47%" @close="closeDrawer">
		<template #extra>
			<a-button type="primary" @click="clearFilters">Clear Filter</a-button>
		</template>
		<a-table
			v-if="isListDrawerOpen"
			:columns="recipients"
			:scroll="{ x: 'max-content' }"
			style="width: 100%"
			bordered
			:data-source="recipientsList"
			:loading="loading"
			class="tableBody"
			@change="handleListChange"
			:pagination="{
        				current: recipientsPagination.current,
        				pageSize: recipientsPagination.pageSize,
        				total: recipientsPagination.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
			><template #bodyCell="{ column, index }">
				<template v-if="column.dataIndex === 'index'">
					{{ index + 1 }}
				</template>
			</template>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px">
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
		</a-table>
	</a-drawer>
</template>
<script lang="ts" setup>
	import { getEmailListApi, getRecipientsListApi } from "@/services/api/user";
	import { ref } from "vue";
	import { truncateText } from "../utils/index";
	import dayjs from "dayjs";
	const loading = ref(false);
	const list = ref([]);
	const recipientsList = ref([]);
	const contentsPagination = ref({
		pageSize: 20,
		total: 0,
		current: 1,
		totalPages: 0,
	});
	const contentsListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const contentsListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sort: "modified",
		searchBy: null,
		search: null,
	});

	const recipientsPagination = ref({
		pageSize: 20,
		total: 0,
		current: 1,
		totalPages: 0,
	});
	const recipientsListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const recipientsListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sort: "modified",
		searchBy: null,
		search: null,
	});

	const filteredInfo = ref();
	const recipientsFilteredInfo = ref();

	const isSubjectModalOpen = ref(false);
	const selectSubject = ref<string[]>([]);

	const isBodyModalOpen = ref(false);
	const selectBody = ref<string[]>([]);
	const selectedContentsId = ref("");
	const isListDrawerOpen = ref(false);

	const openEmailDrawer = async (record: any) => {
		selectedContentsId.value = record.key;
		selectedContentsId.value = record._id;
		await fetchRecipients(record._id);
		isListDrawerOpen.value = true;
	};

	const openSubjectModal = (record: any) => {
		selectSubject.value = record.emailSubject;
		isSubjectModalOpen.value = true;
	};

	const openBodyModal = (record: any) => {
		selectBody.value = record.emailBody.replace(/\n/g, "<br/>");
		isBodyModalOpen.value = true;
	};

	const closeDrawer = () => {
		isListDrawerOpen.value = false;
		recipientsListFilter.value.search = "";
		recipientsListFilter.value.searchBy = "";
	};

	const column = [
		{
			title: "Subject",
			dataIndex: "emailSubject",
			key: "emailSubject",
			width: 170,
		},
		{
			title: "Body ",
			dataIndex: "emailBody",
			key: "emailBody",
			width: 170,
		},

		{
			title: "Date Sent",
			dataIndex: "createdAt",
			key: "createdAt",
			width: 150,
			align: "center",
		},
		{
			title: "Action",
			key: "action",
			width: 100,
			slots: { customRender: "action" },
			align: "center",
		},
	];

	const recipients = [
		{
			title: "",
			dataIndex: "index",
			key: "index",
			width: 70,
		},
		{
			title: "User",
			dataIndex: "_id",
			key: "_id",
			width: 100,
			customFilterDropdown: true,
		},
		{
			title: "Email ",
			dataIndex: "identifier",
			key: "identifier",
			width: 170,
			customFilterDropdown: true,
		},
	];
	const handleTableChange = (pagination: any, filters: any) => {
		filteredInfo.value = filters;
		contentsListPagination.value.pageNo = Number(pagination.current);
		contentsListFilter.value.pageNo = Number(pagination.current);
		contentsListPagination.value.pageLimit = Number(pagination.pageSize);
		fetchContentsListApi();
	};

	const handleListChange = (pagination: any, filters: any) => {
		recipientsFilteredInfo.value = filters;
		recipientsListPagination.value.pageNo = Number(pagination.current);
		recipientsListFilter.value.pageNo = Number(pagination.current);
		recipientsListPagination.value.pageLimit = Number(pagination.pageSize);
		fetchRecipients(selectedContentsId.value);
	};

	const fetchContentsListApi = async () => {
		try {
			loading.value = true;
			const apiRes = await getEmailListApi({ pageNo: contentsListPagination.value.pageNo, pageLimit: contentsListPagination.value.pageLimit }, { ...contentsListFilter.value });
			list.value = apiRes?.item || [];
			contentsPagination.value.total = apiRes?.pagination.total;
			contentsPagination.value.current = apiRes?.pagination.currentPage;
			contentsPagination.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};
	fetchContentsListApi();

	const fetchRecipients = async (contentsId: string) => {
		try {
			loading.value = true;
			const apiRes = await getRecipientsListApi({ pageNo: recipientsListPagination.value.pageNo, pageLimit: recipientsListPagination.value.pageLimit }, { ...recipientsListFilter.value }, contentsId);
			recipientsList.value = apiRes?.items || [];
			recipientsPagination.value.total = apiRes?.pagination.total;
			recipientsPagination.value.current = apiRes?.pagination.currentPage;
			recipientsPagination.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		recipientsListFilter.value.searchBy = dataIndex;
		recipientsListFilter.value.search = selectedKeys[0].trim();
		confirm();
		fetchRecipients(selectedContentsId.value);
	};

	const clearFilters = async () => {
		recipientsListFilter.value.search = "";
		recipientsListFilter.value.searchBy = "";
		recipientsListFilter.value = {
			pageNo: 1,
			pageLimit: 20,
		};
		fetchRecipients(selectedContentsId.value);
	};

	const handleReset = async (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void) => {
		confirm();
		recipientsListFilter.value.search = "";
		recipientsListFilter.value.searchBy = "";
		fetchRecipients(selectedContentsId.value);
	};
</script>
<style scoped>
	.EmailListContainer {
		padding: 22px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}

	.tag {
		padding: 5px 8px;
		font-size: 13px;
		margin: 6px 4px;
	}
	.modelTex {
		font-size: 16px;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
