<template>
	<div class="pushEmailContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a', fontWeight: 'bold', fontSize: '20px', marginBottom: '20px' }"> Push Email </a-breadcrumb-item>
			</a-breadcrumb>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Users </span>
				</div>
				<!-- <div class="detailsContainer">
					<a-form-item label="All Users"> </a-form-item>
					<span>
						<a-switch v-model:checked="allUser" @change="handleAllUserChange" />
					</span>
				</div> -->

				<div class="detailsContainer">
					<a-form-item label="Users Id"> </a-form-item>
					<span>
						<div class="inputContainer">
							<a-button @click="showInput" class="addIdBtn">+ userID</a-button>
						</div>
					</span>
				</div>

				<div class="selectedDataContainer">
					<div v-for="tag in userIdTag" :key="tag" class="tag">
						<a-tag closable @close="handleClose(tag)" class="userIdTag">
							{{ tag }}
						</a-tag>
					</div>
					<a-input v-if="inputVisible" v-model:value="userIdInputValue" @blur="handleInputConfirm" @keyup.enter="handleInputConfirm" class="userIdBtn" />
				</div>
			</div>
		</div>

		<div class="email-param-Container">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Email Template</span>
				</div>

				<div class="detailsContainer" :style="{ 'margin-bottom': '35px' }">
					<a-form-item label="Subject"> </a-form-item>
					<a-textarea rows="2" class="subjectArea" showCount :maxlength="200" v-model:value="emailSubject" :class="{ 'show-count-bottom-right': true }"></a-textarea>
				</div>
				<div class="detailsContainer" :style="{ 'margin-bottom': '35px' }">
					<a-form-item label="Body" class="text-bucket"> </a-form-item>
					<a-textarea rows="7" class="textArea" v-model:value="emailBody" :class="{ 'show-count-bottom-right': true }"></a-textarea>
				</div>
			</div>
		</div>

		<div class="final-send-btn-container">
			<a-button type="primary" class="clearBtn" @click="clearForm">Clear</a-button>
			<a-button type="primary" class="saveBtn" @click="collectAndSendEmail">Send Email</a-button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { createVNode, ref } from "vue";
	import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { Modal } from "ant-design-vue";
	import { sendManualEmail } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	const userIdTag = ref<string[]>([]);
	const inputVisible = ref(false);
	const userIdInputValue = ref("");
	const emailSubject = ref("");
	const emailBody = ref("");

	const showInput = () => {
		inputVisible.value = true;
	};

	const handleClose = (removedTag: string) => {
		console.log(removedTag, 81);
		userIdTag.value = userIdTag.value.filter((tag) => tag !== removedTag);
	};

	const collectAndSendEmail = async () => {
		if (userIdTag.value.length === 0 || !emailBody.value || !emailSubject.value) {
			openNotificationWithIcon("error", "Please provide valid all the required fields To send email ");
			return; // Return early to stop further execution
		}
		Modal.confirm({
			title: "Are you sure want to Send Email?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",

			onOk: async () => {
				const data = {
					userIds: userIdTag.value,
					emailBody: emailBody.value,
					emailSubject: emailSubject.value,
				};
				await sendManualEmail(data);
				clearForm();
			},
		});
	};
	const handleInputConfirm = () => {
		if (userIdInputValue.value.trim() && !userIdTag.value.includes(userIdInputValue.value.trim())) {
			userIdTag.value.push(userIdInputValue.value.trim());
		}
		inputVisible.value = false;
		userIdInputValue.value = "";
	};
	const clearForm = () => {
		userIdTag.value = [];
		emailSubject.value = "";
		emailBody.value = "";
	};
</script>
<style scoped>
	.pushEmailContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 100%;
		margin-bottom: 35px;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.selectedDataContainer {
		display: flex;
		flex-wrap: wrap; /* This line enables the wrapping behavior */
		margin-right: 100px;
		margin-bottom: 20px;
	}

	.email-param-Container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}

	.text-bucket {
		width: 14rem;
	}

	.subjectArea {
		width: 43rem;
		height: 5rem;
	}
	.textArea {
		width: 43rem;
		height: 15rem;
	}

	.userIdTag {
		padding: 5px 16px;
		text-align: center;
		font-size: 15px;
		margin-bottom: 15px;
		cursor: pointer;
	}
	.inputContainer {
		position: relative;
		margin-bottom: 15px;
	}
	.addIdBtn {
		margin-bottom: 10px;
		width: 100px;
		position: absolute;
		right: 0;
	}
	.userIdBtn {
		width: 200px;
		height: 33px;
	}
	.final-send-btn-container {
		display: flex;
		justify-content: center;
		gap: 20px;
	}
</style>
