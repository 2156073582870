<template>
	<div class="EmailListContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }">Location List</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" @click="addLocations" class="clear-filter" style="margin-right: 10px">Add Location</a-button>
			<a-button type="primary" @click="clearFilters" class="clear-filter">Clear Filter</a-button>
		</div>

		<a-table
			:columns="column"
			:scroll="undefined"
			style="width: 100%"
			bordered
			:data-source="list"
			:loading="loading"
			@change="handleChange"
			class="tableBody"
			:pagination="{
        				current: pagination.current,
        				pageSize: pagination.pageSize,
        				total: pagination.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
		>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column.dataIndex === 'createdAt'">
					<span>{{ record.createdAt ? dayjs.utc(record.createdAt).format("DD-MMM-YYYY  HH:mm") : "" }}</span>
				</template>

				<template v-else-if="column.dataIndex === 'modifiedAt'">
					<span>{{ record.modifiedAt ? dayjs.utc(record.modifiedAt).format("DD-MMM-YYYY  HH:mm") : "" }}</span>
				</template>

				<template v-if="column.dataIndex === 'tag'">
					<span>
						<div v-for="(t, index) in record.tag" @click="openTagModal(record)" class="tagContainer">
							<div class="tags">
								<a-tag :key="t" class="tag" v-if="index <= 4">
									{{ t }}
								</a-tag>
							</div>
						</div>

						<a-tag v-if="record.tag.length >= 5" :style="{ padding: '5px 8px', fontSize: '13px' }" @click="openTagModal(record)">More...</a-tag>
					</span>
				</template>
			</template>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px">
					<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>

			<template #action="{ record }">
				<a-button type="primary" @click="LocationItemAddDrawer(record)" class="buttons">Add</a-button>
				<a-button class="buttons" @click="removeLocation(record)"><DeleteOutlined />Delete</a-button>
			</template>
		</a-table>

		<a-modal v-model:visible="isTagModalOpen" title="Tag ">
			<template #footer>
				<a-button key="submit" type="primary" @click="isTagModalOpen = false"> Close </a-button>
			</template>
			<div v-if="selectedTagList.length > 0">
				<a-tag v-for="tag in selectedTagList" class="tag">
					{{ tag }}
				</a-tag>
			</div>
			<div v-else>No Location Tag found</div>
		</a-modal>

		<!-- <a-drawer v-model:visible="isAddLocationDrawer"  placement="right" width="40%" @close="closeAddLocationDrawer">
			<template #title> -->
		<a-drawer v-model:visible="isAddLocationDrawer" placement="right" width="30%" @close="closeAddLocationDrawer">
			<template #title>
				<div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
					<span>{{ drawerTitle }}</span>
					<a-button type="primary" @click="saveLocation" class="saveButton">Save</a-button>
				</div>
			</template>
			<a-form :model="recordVal" layout="horizontal" :labelCol="{ span: 6 }">
				<a-form-item label="Key">
					<div style="display: flex; gap: 10px">
						<a-input step="0" v-model:value="recordVal.key" :disabled="!!recordVal._id" />
					</div>
				</a-form-item>
				<a-form-item label="Tag">
					<div style="display: flex; gap: 10px">
						<a-input step="0" v-model:value="itemVal" />
					</div>
				</a-form-item>
				<a-form-item label="Update for" v-if="!recordVal._id">
					<a-select v-model:value="recordVal.updateFor">
						<a-select-option value="locality">Locality</a-select-option>
						<a-select-option value="city">City</a-select-option>
						<a-select-option value="region">Region</a-select-option>
						<a-select-option value="country">Country</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-drawer>
	</div>
</template>

<script lang="ts" setup>
	const loading = ref(false);
	import { deleteLocation, getLocationListApi, locationItemAddApi, NewLocationAddApi } from "@/services/api/user";
	import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { createVNode, ref } from "vue";
	import dayjs from "dayjs";
	import { openNotificationWithIcon } from "@/utils";
	import { Modal } from "ant-design-vue";
	const recordVal = ref<any>({});
	const list = ref([]);
	const isAddLocationDrawer = ref(false);
	const selectedTagList = ref<string[]>([]);
	const isTagModalOpen = ref(false);
	const drawerTitle = ref("");
	const itemVal = ref<string>();
	const pagination = ref({
		pageSize: 20,
		total: 0,
		current: 1,
		totalPages: 0,
	});
	const listPagination = ref({ pageNo: 1, pageLimit: 20 });
	const listFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sort: "modified",
		searchBy: null,
		search: null,
	});

	const closeAddLocationDrawer = () => {
		isAddLocationDrawer.value = false;
	};

	const LocationItemAddDrawer = (record: any) => {
		recordVal.value = { ...record };
		isAddLocationDrawer.value = true;
		drawerTitle.value = "Add Location Tag";
	};

	const addLocations = () => {
		recordVal.value = {};
		isAddLocationDrawer.value = true;
		drawerTitle.value = "Add Location";
	};
	const column = [
		{
			title: "Locations",
			dataIndex: "key",
			key: "key",
			customFilterDropdown: true,
			width: 230,
		},
		{
			title: "Words",
			dataIndex: "tag",
			customFilterDropdown: true,
			key: "tag",
		},
		{
			title: "Use For",
			dataIndex: "updateFor",
			key: "updateFor",
			category: "updateFor",
			align: "center",
			filters: [
				{ text: "locality", value: "locality" },
				{ text: "City", value: "city" },
				{ text: "Region", value: "region" },
				{ text: "Country", value: "country" },
			],
			filterMultiple: false,
		},
		{
			title: "ModifiedAt",
			dataIndex: "modifiedAt",
			key: "modifiedAt",
			width: 160,
			align: "center",
		},
		{
			title: "Action",
			key: "action",
			slots: { customRender: "action" },
			align: "center",
		},
	];

	const openTagModal = (record: any) => {
		selectedTagList.value = record.tag;
		isTagModalOpen.value = true;
	};

	const locationListApi = async () => {
		try {
			loading.value = true;
			const apiRes = await getLocationListApi({ pageNo: listPagination.value.pageNo, pageLimit: listPagination.value.pageLimit }, { ...listFilter.value });
			list.value = apiRes?.item || [];
			pagination.value.total = apiRes?.pagination.total;
			pagination.value.current = apiRes?.pagination.currentPage;
			pagination.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};
	locationListApi();

	const handleChange = (pagination: any, filters: any) => {
		listFilter.value = filters;
		if (filters.updateFor) {
			listFilter.value.search = filters.updateFor;
			listFilter.value.searchBy = "updateFor";
		}
		listPagination.value.pageNo = Number(pagination.current);
		listFilter.value.pageNo = Number(pagination.current);
		listPagination.value.pageLimit = Number(pagination.pageSize);
		locationListApi();
	};

	const saveLocation = async () => {
		try {
			loading.value = true;
			recordVal.value.tag = itemVal.value;

			if (!recordVal.value.key || !recordVal.value.tag) {
				openNotificationWithIcon("error", "Please fill in all the remaining fields.");
				loading.value = false;
				return;
			}

			const payload = {
				key: recordVal.value.key.toLowerCase().trim(),
				tag: recordVal.value.tag.toLocaleLowerCase().trim(),
				updateFor: recordVal.value.updateFor.toLocaleLowerCase().trim(),
			};

			if (recordVal.value._id) {
				try {
					const { _id, ...data } = recordVal.value;
					const saveItem = await locationItemAddApi(data, _id);
					if (saveItem.valid) {
						openNotificationWithIcon("success", saveItem.message);
					}
				} catch (error: any) {
					console.error(error);
				}
			} else {
				try {
					const saveItem = await NewLocationAddApi(payload);
					if (saveItem.valid) {
						openNotificationWithIcon("success", saveItem.message);
					}
				} catch (error: any) {
					console.error(error);
				}
			}

			await closeAddLocationDrawer();
			await clearForm();
			await locationListApi();
			loading.value = false;
		} catch (error) {
			console.error("Error saving location:", error);
			loading.value = false;
		}
	};

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();

		if (dataIndex === "updateFor") {
			listFilter.value.searchBy = dataIndex;
			listFilter.value.search = selectedKeys[0] && selectedKeys[0].trim();
		}
		listFilter.value.searchBy = dataIndex;
		listFilter.value.search = selectedKeys[0].trim();
		locationListApi();
	};

	const handleReset = (clearFilters: any, confirm: any) => {
		clearFilters();
		confirm();
		listFilter.value.searchBy = null;
		listFilter.value.search = null;
		locationListApi();
	};

	const clearForm = () => {
		recordVal.value = {};
		itemVal.value = "";
	};

	const clearFilters = () => {
		listFilter.value.searchBy = null;
		listFilter.value.search = null;
		locationListApi();
	};

	const removeLocation = async (record: any) => {
		Modal.confirm({
			title: "Are you sure to delete Language?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				try {
					const deleteResult = await deleteLocation(record._id);
					if (deleteResult.valid) {
						locationListApi();
						openNotificationWithIcon("success", "Location deleted successfully");
					} else {
						openNotificationWithIcon("error", "Failed to delete Location");
					}
				} catch (error) {
					console.error("Error", error);
					openNotificationWithIcon("error", "An error occurred while deleting location");
				}
			},
		});
	};
</script>
<style scoped>
	.buttons {
		margin-right: 25px;
	}
	.EmailListContainer {
		padding: 22px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.tagContainer {
		display: inline-flex;
		flex-wrap: nowrap;
		gap: 4px;
	}
	.tag {
		padding: 5px 8px;
		font-size: 13px;
		margin: 6px 4px;
	}
	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
