<template>
	<a-layout>
		<div
			:style="{
				background: 'white',
				height: 'calc(100vh - 95px)',
				borderRadius: '0 8px  0 0 ',
				marginTop: '5px',
			}"
		>
			<a-layout-sider v-model:collapsed="collapsed" collapsible :style="{ background: '#fff', borderRadius: '0 8px  0 0' }">
				<a-menu
					v-model:selectedKeys="selectedKeys"
					mode="inline"
					:style="{
						background: '#fff',
						border: 'none',
						borderRadius: '0 8px 0 0 ',
						fontWeight: 700,
					}"
					v-model:openKeys="openKeys"
				>
					<a-menu-item key="/">
						<HomeOutlined />
						<span>
							<router-link to="/">Dashboard</router-link>
						</span>
					</a-menu-item>
					<a-menu-item key="/analytics" :style="{ fontWeight: 500 }">
						<router-link to="/analytics">
							<span>
								<!-- <img src="analytics-icon.png" :style="{ width: '15px', fontSize: '14px' }" /> -->
								<PieChartOutlined />
								<span :style="{ marginLeft: analyticsMarginLeft, fontWeight: 700 }">Analytics</span>
							</span>
						</router-link>
					</a-menu-item>
					<a-sub-menu key="sub1" @click="handleSubMenuClick('sub1')">
						<template #title>
							<span>
								<UserOutlined />
								<span>User</span>
							</span>
						</template>

						<a-menu-item key="/members" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/members">
								<span>
									<TeamOutlined />
									<span>Members</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/profile" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/profile">
								<span>
									<ProfileOutlined />
									<span>Profiles</span>
								</span>
							</router-link>
						</a-menu-item>

						<a-menu-item key="/celebrities" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/celebrities">
								<span>
									<StarOutlined />
									<span>Celebrities</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/newcelebrities" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/newcelebrities">
								<span>
									<StarOutlined />
									<span>Celebrities V2</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/admin" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/admin">
								<span>
									<UserSwitchOutlined />
									<span>Admin</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/reported" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/reported">
								<span>
									<AuditOutlined />
									<span>Reported</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/feedback" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/feedback">
								<span>
									<CommentOutlined />
									<span>Feedback</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/deleted" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/deleted">
								<span>
									<UsergroupDeleteOutlined />
									<span>Deleted</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/incomplete-profile" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/incomplete-profile">
								<span>
									<UserDeleteOutlined />
									<span>Incomplete profile </span>
								</span>
							</router-link>
						</a-menu-item>
					</a-sub-menu>

					<a-sub-menu key="sub4" @click="handleSubMenuClick('sub4')"
						><template #title>
							<span>
								<MessageOutlined />
								<span>Notification</span>
							</span>
						</template>
						<a-menu-item key="/push-notification" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/push-notification">
								<span>
									<SendOutlined />
									<span>Send Notification</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/notification-list" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/notification-list">
								<span>
									<HistoryOutlined />
									<span>History Notification</span>
								</span>
							</router-link>
						</a-menu-item>
					</a-sub-menu>

					<a-sub-menu key="sub5" @click="handleSubMenuClick('sub5')"
						><template #title>
							<span>
								<MailOutlined />
								<span>Email</span>
							</span>
						</template>
						<a-menu-item key="/email-contents" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/email-contents">
								<span>
									<SendOutlined />
									<span>Send Email</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/email-recipients" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/email-recipients">
								<span>
									<HistoryOutlined />
									<span>Email History</span>
								</span>
							</router-link>
						</a-menu-item>
					</a-sub-menu>

					<a-sub-menu key="sub2" @click="handleSubMenuClick('sub2')">
						<template #title>
							<span>
								<img src="/Astro.png" :style="{ width: '16px', fontSize: '14px' }" />
								<span :style="{ marginLeft: analyticsMarginLeft }">Astrology</span>
							</span>
						</template>
						<a-menu-item key="/synastry" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/synastry">
								<span>
									<img class="astro-icon" src="/Astro/synastry - NOR.png" />
									<span>Synastry</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/astakuta" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/astakuta">
								<span>
									<img class="astro-icon" src="/Astro/Guna - UnSelected.png" />
									<span>Aṣṭakūṭa</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="vibrational" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/vibrational">
								<span>
									<img class="astro-icon" src="/Astro/Vibe - NOR.png" />
									<span>Vib. Synastry</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="progressed" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/progressed">
								<span>
									<img class="astro-icon" src="/Astro/Prog NOR.png" />
									<span>Progr. Synastry</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="progressed" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/progressed">
								<span>
									<img class="astro-icon" src="/Astro/Current UnSelected.png" />
									<span>Current Trends</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="lucky" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/lucky">
								<span>
									<img class="astro-icon" src="/Astro/Lucky - NOR.png" />
									<span>Lucky Times</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="protocols" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/protocols">
								<span>
									<img class="astro-icon" src="/Astro/Protocol - NOR.png" />
									<span>Protocols</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="stations" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/stations">
								<span>
									<img class="astro-icon" src="/Astro/Panchanga - NOR.png" />
									<span>Pañcāṅga</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="se" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/se">
								<span>
									<img class="astro-icon" src="/Astro/Astro UI - NOR.png" />
									<span>Astro UI</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="se" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/se">
								<span>
									<img class="astro-icon" src="/Astro/Stations - NOR.png" />
									<span>Stations</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="se" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/se">
								<span>
									<img class="astro-icon" src="/Astro/files - NOR.png" />
									<span>SE Files</span>
								</span>
							</router-link>
						</a-menu-item>
					</a-sub-menu>
					<a-sub-menu key="sub3" @click="handleSubMenuClick('sub3')">
						<template #title>
							<span>
								<SettingFilled />
								<span>Settings</span>
							</span>
						</template>
						<a-menu-item key="/appsettings" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/appsettings">
								<span>
									<span>App settings</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/forceUpadte" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/forceUpadte">
								<span>
									<span>Force Update</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/ipWhitelist" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/ipWhitelist">
								<span>
									<span>IP whitelist</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/languages" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/languages">
								<span>
									<span>Languages</span>
								</span>
							</router-link>
						</a-menu-item>
						<a-menu-item key="/location-map" :style="{ fontWeight: 500 }" @click.stop>
							<router-link to="/location-map">
								<span>
									<span>Location map</span>
								</span>
							</router-link>
						</a-menu-item>
					</a-sub-menu>
				</a-menu>
			</a-layout-sider>
		</div>
		<a-layout>
			<a-layout-content class="main-layout">
				<div :style="{ height: `${Number(height) - 55}px`, overflowY: 'scroll' }">
					<router-view />
				</div>
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>

<script lang="ts" setup>
	import { ref, watch } from "vue";
	import { UserOutlined, HomeOutlined, TeamOutlined, StarOutlined, UserSwitchOutlined, UsergroupDeleteOutlined, CommentOutlined, AuditOutlined, FieldTimeOutlined, SettingOutlined, Loading3QuartersOutlined, GatewayOutlined, StopOutlined, FileOutlined, StepBackwardOutlined, SettingFilled, BellOutlined, SendOutlined, HistoryOutlined, NotificationOutlined, MessageOutlined, PieChartFilled, PieChartOutlined, UserDeleteOutlined, ProfileOutlined, MailOutlined } from "@ant-design/icons-vue";
	import router from "@/router";
	import { useScreenSize } from "@/utils";
	const key = ref();
	const marginLeft = ref("100px");
	const analyticsMarginLeft = ref("10px");
	const selectedKeys = ref<string[]>([key.value]);
	const { height } = useScreenSize();
	const collapsed = ref<boolean>(false);
	const openKeys = ref<string[]>([]);
	const closeSider = ref();

	const handleSubMenuClick = (key: string) => {
		if (closeSider.value == key) {
			openKeys.value = [];
			closeSider.value = "";
		} else {
			openKeys.value = [key];
			closeSider.value = key;
		}
	};

	watch(collapsed, (newVal) => {
		marginLeft.value = newVal ? "200px" : "200px";
		analyticsMarginLeft.value = newVal ? "30px" : "10px";
	});

	setTimeout(() => {
		selectedKeys.value = [router.currentRoute.value.path];
	}, 100);
</script>

<style scoped>
	.header-section {
		padding: 5px;
		border-bottom: 1px solid #f5f5f5;
		display: flex;
	}

	.main-layout {
		background: #fff;
		margin: 5px 0 0 5px;
		border-radius: 8px;
		min-height: calc(100vh - 70px);
	}

	.astro-icon {
		width: 15px;
		margin-right: 10px;
	}
</style>
